import Header from "./Header";

function App() {
  return (
    <div>
      <Header text={"개인정보 처리방침"} />
      <div className="content">
        <h1>제 1조 개인정보의 처리 목적</h1>
        <h3>
          ZeroBot은 다음의 목적을 위하여 개인정보를 처리합니다.
          처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,
          이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </h3>
        <ol>
          <li>
            <dl>
              <dt>제로봇 회원 가입 및 관리</dt>
              <dd>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>디스코드 서버 참가 및 관리</dt>
              <dd>각 서버에 맞는 서비스를 제공에 따른 서버 식별·인증, 서비스 부정이용 방지, 고층처리 목적으로 개인정보를 처리합니다.</dd>
            </dl>
          </li>
        </ol>
        <h1>제 2조 개인정보의 처리 및 보유기간</h1>
        <h3>ZeroBot은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</h3>
        <h3>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</h3>
        <ol>
          <li>
            <p>
              제로봇 회원 가입 및 관리 : 회원 탈퇴 시까지<br />
              다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
            </p>
            <ul>
              <li>서비스 이용약관 위반에 따른 서비스 제한 조치를 받은 경우에는 해당 사유 종료 시까지</li>
            </ul>
          </li>
          <li>
            <p>
              디스코드 서버 참가 및 관리 : 디스코드 서버에서 제로봇이 나갈 시까지<br />
              다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
            </p>
            <ul>
              <li>서비스 이용약관 위반에 따른 서비스 제한 조치를 받은 경우에는 해당 사유 종료 시까지</li>
            </ul>
          </li>
          <li>
            <p>
              서버에서 삭제·수정된 메시지 : 해당 메시지를 확인하는 명령어실행 시까지<br />
              다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
            </p>
            <ul>
              <li>
                <dt>5개의 메시지가 이미 저장된 경우 다음 메시지가 삭제·수정될때 가장 마지막 메시지를 제외한 5개의 메시지를 저장합니다.</dt>
                <dd>※ 이는 삭제와 수정이 따로 카운트됩니다.</dd>
              </li>
            </ul>
          </li>
        </ol>
        <h1>제 3조 정보주체의 권리·의무 및 행사방법</h1>
        <h3>정보주체는 ZeroBot에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</h3>
        <h3>
          제 1항에 따른 권리 행사는 ZeroBot에 대해 개인정보보호법 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며.
          ZeroBot은 이에 대해 지체없이 조치하겠습니다.
        </h3>
        <h3>
          제 1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.
          이 경우 "개인정보 처리 방법에 관한고시(제2020-7호)" 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </h3>
        <h3>개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</h3>
        <h3>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</h3>
        <h3>ZeroBot은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</h3>
        <h1>제 4조 처리하는 개인정보 항목</h1>
        <h3>ZeroBot은 다음의 개인정보 항목을 처리하고 있습니다.</h3>
        <ol>
          <li>
            <p>제로봇 회원 가입 및 관리</p>
            <ul>
              <li>필수항목 : 사용자이름, 태그, 아이디 등의 유저정보</li>
              <li>선택항목 : 지역코드, 학교코드</li>
            </ul>
          </li>
          <li>
            <p>디스코드 서버 참가 및 관리</p>
            <ul>
              <li>필수항목 : 서버이름, 아이디, 멤버들의 정보, 삭제·수정된 메시지</li>
            </ul>
          </li>
          <li>
            <p>인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.</p>
            <ul>
              <li>IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록</li>
            </ul>
          </li>
        </ol>
        <h1>제 5조 개인정보의 파기</h1>
        <h3>ZeroBot은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</h3>
        <h1>제 6조 개인정보 보호책임자</h1>
        <h3>
          ZeroBot은 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </h3>
        <h3>개인정보 보호 책임자</h3>
        <ul>
          <li>연락처 : 디스코드 zero624#0624</li>
        </ul>
        <h1>제 7조 수집한 개인정보의 위탁</h1>
        <h3>ZeroBot은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</h3>
        <ol>
          <li>
            <p>데이터 저장</p>
            <ul>
              <li>위탁받는 자 : www.mongodb.com</li>
              <li>위탁하는 업무의 내용 : 수집된 개인정보의 저장</li>
            </ul>
          </li>
        </ol>
        <h1>제 8조 정보주체의 권익침해에 대한 구제방법</h1>
        <h3>
          정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다.
          이 밖에 기타 개인정보침해 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        </h3>
        <ol>
          <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
          <li>개인정보침해신고센터 : (국번없이) 188 (privacy.kisa.or.kr)</li>
          <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
          <li>경찰청 : (국번없이) 182 (cyberbureau.police.go.kr)</li>
        </ol>
      </div>
    </div>
  );
}

export default App;
