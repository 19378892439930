import styles from "./Header.module.css";

function Header({ text }) {
    return (
        <header className={styles.header}>
            <div className={styles.social}>
                <a href="https://discord.gg/3w4aKhpUVy">
                    <img src="/discord.png" title="Discord - ZeroBot" alt="Discord - ZeroBot" />
                </a>
            </div>
            <div className={styles.icon}>
                <img src="/logo512.png" title="Discord - ZeroBot" alt="Discord - ZeroBot" width="100px" />
                <p>{text}</p>
            </div>
        </header>
    );
}

export default Header;
