import { Route, Routes } from 'react-router-dom';
import Privacy from './Privacy';
import Terms from './Terms';

function App() {
  return (
    <Routes>
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
}

export default App;
