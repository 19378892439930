import Header from "./Header";

function App() {
  return (
    <div>
      <Header text={"서비스 이용약관"} />
      <div className="content">
      </div>
    </div>
  );
}

export default App;
